<booking-record-filter *ngIf="showOpened" (filterEvent)="applyFilter($event)" (exportResa)="exportResa()">
</booking-record-filter>

<table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChanged()" class="w-100 h-100 table-wrapper"
  data-testid="booking-list">

  <ng-container matColumnDef="id">

    <th mat-header-cell *matHeaderCellDef class="d-flex align-items-center h-100">
      <mat-checkbox *ngIf="showOpened" [checked]="allChecked" color="primary" (change)="checkAll()">

      </mat-checkbox>
      <div mat-sort-header class="pl-2">N° Réservation</div>
    </th>
    <td mat-cell *matCellDef="let booking">
      <mat-checkbox *ngIf="showOpened" [checked]="selectedBookings[booking.businessId]" color="primary"
        (change)="toggleCheck(booking)" data-testid="booking-list-check-export">
        {{ booking.businessId }}
      </mat-checkbox>
      <span *ngIf="!showOpened">{{ booking.businessId }}</span>
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
    <td mat-cell *matCellDef="let booking">{{ booking.status | BookingStatusPipe }}</td>
  </ng-container>
  <ng-container matColumnDef="creationDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Réservation</th>
    <td mat-cell *matCellDef="let booking">{{ booking.creationDate | date: 'dd/MM/yyyy': '+0200'}}</td>
  </ng-container>
  <ng-container matColumnDef="destination">
    <th mat-header-cell *matHeaderCellDef>Destination</th>
    <td mat-cell *matCellDef="let booking">{{ booking.productRecord.destinationName }}</td>
  </ng-container>
  <ng-container matColumnDef="productReference">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Code Produit</th>
    <td mat-cell *matCellDef="let booking">{{ booking.productRecord.productReference }}</td>
  </ng-container>
  <ng-container matColumnDef="product">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Produit</th>
    <td mat-cell *matCellDef="let booking">{{ booking.productRecord.productName }}</td>
  </ng-container>
  <ng-container matColumnDef="startCity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Départ</th>
    <td mat-cell *matCellDef="let booking">
      {{ booking.startCityName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="departureDate">
    <th mat-header-cell *matHeaderCellDef>Date voyage</th>
    <td mat-cell *matCellDef="let booking">{{ booking.startDate | date: 'dd/MM/yyyy': '+0200' }}</td>
  </ng-container>
  <ng-container matColumnDef="nbNights">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nuits</th>
    <td mat-cell *matCellDef="let booking" class="centre">{{ booking.nbNights }}</td>
  </ng-container>
  <ng-container matColumnDef="categoryName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header class="centre">Catégorie</th>
    <td mat-cell *matCellDef="let booking" class="centre">{{ booking.productRecord.categoryName }}</td>
  </ng-container>
  <ng-container matColumnDef="firstPax">
    <th mat-header-cell *matHeaderCellDef>1er pax</th>
    <td mat-cell *matCellDef="let booking">{{ booking.travelerRecord?.travelerInfos ?
      booking.travelerRecord?.travelerInfos[0].lastName : '' }}</td>
  </ng-container>
  <ng-container matColumnDef="total">
    <th mat-header-cell *matHeaderCellDef>Total dossier</th>
    <td mat-cell *matCellDef="let booking">{{ booking.bookingPrice.totalPrice + '€' }}</td>
  </ng-container>
  <ng-container matColumnDef="supplier">
    <th mat-header-cell *matHeaderCellDef>Producteur</th>
    <td mat-cell *matCellDef="let booking" class="centre">{{ booking.distributionRecord.producerCompanyName }}</td>
  </ng-container>
  <ng-container matColumnDef="seller">
    <th mat-header-cell *matHeaderCellDef>Distributeur</th>
    <td mat-cell *matCellDef="let booking">{{ booking.distributionRecord.supplierName }}</td>
  </ng-container>
  <ng-container matColumnDef="distribution">
    <th mat-header-cell *matHeaderCellDef>Canal</th>
    <td mat-cell *matCellDef="let booking">{{ booking.distributionRecord.distribution }}</td>
  </ng-container>
  <ng-container matColumnDef="balenoId">
    <th mat-header-cell *matHeaderCellDef>ID baleno</th>
    <td mat-cell *matCellDef="let booking">{{ booking.balenoId }}</td>
  </ng-container>
  <ng-container matColumnDef="options">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let booking" class="d-flex align-items-center h-100">
      <button *ngIf="showOpened" mat-icon-button matTooltip="Afficher les détails"
        data-testid="booking-list-open-details" [routerLink]="['/booking-record', booking.id]"
        [queryParams]="producerId ? {producerId: producerId} : {}">
        <mat-icon class="colored">zoom_in</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Exporter vers baleno"
        (click)="exportBaleno(booking.id, booking.businessId, booking.balenoId)"
        data-testid="booking-list-export-baleno">
        <mat-icon class="colored">exit_to_app</mat-icon>
      </button>
    </td>
  </ng-container>
  <ng-container matColumnDef="consult">
    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
    <td mat-cell *matCellDef="let booking"><span>
        <mat-icon *ngIf="showOpened" [class.orange-colored]="!booking.opened"
          [class.transparent-colored]="booking.opened">album
        </mat-icon>
      </span></td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.draft-line]="row.status === 'DRAFT'"
    [class.cancelled-line]="row.status === 'CANCELLED'" [class.failed-line]="row.status === 'FAILURE'"></tr>

  <!-- Row shown when there is no matching data. -->
  <tr mat-row *matNoDataRow>
    <td *ngIf="nbBookings === 0 || !nbBookings " class="w-100">Aucune réservation à afficher</td>
  </tr>
</table>

<mat-paginator *ngIf="showOpened" class="col-sm-12 w-100" [length]="nbBookings" [pageSize]="itemsPerPage"
  (page)="changePage()" aria-label="Select page of bookings"></mat-paginator>